<template>
  <div ref="selectDrop" class="select-drop" :style="{ width: componentWidth }">
    <v-menu
      v-model="menu"
      offset-y
      bottom
      allow-overflow
      :max-width="getMenuMaxWidth"
    >
      <template #activator="{ on }">
        <v-text-field
          append-icon="$mdi-chevron-down"
          :placeholder="placeholder"
          :value="computedValue"
          :color="color"
          :class="{ 'menu-open': !!menu }"
          outlined
          dense
          hide-details
          readonly
          v-on="on"
        />
      </template>
      <v-card>
        <!-- search field -->
        <v-text-field
          v-model="search"
          class="px-3 py-1"
          placeholder="Search"
          append-icon="$mdi-magnify"
          flat
          dense
          solo
          hide-details
          autofocus
        />
        <v-divider />
        <!-- group items -->
        <v-list v-if="groups">
          <div>
            <template v-for="(group, g) of groups">
              <div v-if="hasItems(group)" :key="g" class="group-list">
                <div class="px-5 py-1 d-flex align-center">
                  <span class="ap-gray--text pr-2">{{ group }}</span>
                  <v-divider />
                </div>
                <v-list-item
                  v-for="(item, n) in filteredItems.filter(
                    (item) => item.group === group
                  )"
                  :key="n"
                  class="px-5 py-1 group-item"
                  @click="selection = getValue(item)"
                >
                  {{ getLabel(item) }}
                </v-list-item>
              </div>
            </template>
          </div>
          <v-list-item
            v-for="(item, n) in filteredItems.filter((item) => !item.group)"
            :key="n + 'nogroup'"
            class="px-5 py-1"
            @click="selection = getValue(item)"
          >
            {{ getLabel(item) }}
          </v-list-item>
        </v-list>
        <!-- normal items -->
        <v-list v-else>
          <v-list-item
            v-for="(item, n) in filteredItems"
            :key="n"
            class="px-5 py-1"
            @click="selection = getValue(item)"
          >
            {{ getLabel(item) }}
          </v-list-item>
        </v-list>
        <!-- append slot -->
        <div class="append-slot">
          <slot name="append" />
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Object],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    groups: {
      type: Array,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    width: {
      type: String,
      default: '',
    },
    itemText: {
      type: String,
      default: 'text',
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    menuMaxWidth: {
      type: String,
      default: 'auto',
    },
    scaleMenuToFit: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'ap-dark-blue',
    },
  },
  data: () => ({
    selection: null,
    search: '',
    menu: false,
    menuWidth: 0,
  }),
  computed: {
    computedValue() {
      if (this.selection && typeof this.selection === 'object') {
        return this.selection[this.itemText]
      }

      return this.selection
    },
    getPlaceHolder() {
      return this.selection ? 'value' : 'placeholder'
    },
    filteredItems() {
      return this.items.filter(
        (item) =>
          this.getLabel(item)
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) !== -1
      )
    },
    componentWidth() {
      if (this.width.slice(-1) === '%') {
        return `${this.width}`
      } else if (this.width.slice(-2) === 'px') {
        return this.width
      } else {
        return `${this.width}px`
      }
    },
    getMenuMaxWidth() {
      return this.scaleMenuToFit ? this.menuWidth : this.menuMaxWidth
    },
  },
  watch: {
    value(val) {
      this.selection = val
    },
    selection(val) {
      if (val !== this.value) this.$emit('input', val)
    },
  },
  mounted() {
    this.selection = this.value
    if (this.scaleMenuToFit) {
      this.calculateMenuWidth()
    }
  },
  methods: {
    getValue(item) {
      if (typeof item === 'object') {
        if (this.returnObject) {
          return item
        }

        return item[this.itemValue]
      }

      return item
    },
    getLabel(item) {
      if (typeof item === 'object') {
        return item[this.itemText]
      }

      return item
    },
    hasItems(group) {
      return (
        this.filteredItems.filter((item) => item.group === group).length > 0
      )
    },
    calculateMenuWidth() {
      this.menuWidth = this.$refs.selectDrop.clientWidth
    },
  },
}
</script>

<style scoped lang="scss">
.select-drop {
  .group-list:last-child .group-item:last-child {
    border-bottom: 1px solid var(--v-ap-dark-gray-base);
  }

  ::v-deep .v-input {
    .v-input__icon {
      transition: all 0.25s;
    }

    &.menu-open {
      .v-input__icon {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
