<template>
  <BaseTableHeaderMenuFilter
    v-model="isMenuOpen"
    :is-filter-applied="isFilterApplied"
  >
    <v-sheet style="max-width: 200px">
      <div>
        <v-text-field
          v-model="textFilter"
          hide-details
          clearable
          autofocus
          dense
          flat
          placeholder="Search"
          append-icon="$mdi-magnify"
          solo
        />
      </div>

      <v-divider />

      <div class="pa-2 overflow-y-auto" style="max-height: 200px">
        <div class="d-flex cursor-pointer" @click="onSelectAllToggle">
          <v-simple-checkbox
            v-ripple
            :value="allSelected"
            :indeterminate="someSelected"
            class="mt-0 mr-2 mb-2"
            @click="onSelectAllToggle"
          />
          <div class="font-weight-bold">Select All</div>
        </div>

        <v-checkbox
          v-for="option in shownOptions"
          :key="option.id"
          v-model="selected"
          :value="get(option, field, '')"
          hide-details
          class="mt-0 mb-2"
          dense
        >
          <template #label>
            <div
              class="text-truncate font-weight-bold"
              style="max-width: 150px"
            >
              {{ get(option, field, '') }}
            </div>
          </template>
        </v-checkbox>
      </div>
    </v-sheet>
  </BaseTableHeaderMenuFilter>
</template>

<script>
import BaseTableHeaderMenuFilter from './BaseTableHeaderMenuFilter'
import { get, uniqBy } from 'lodash-es'

export default {
  components: {
    BaseTableHeaderMenuFilter,
  },
  props: {
    field: {
      type: String,
      default: '',
    },
    records: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      get,
      selected: [],
      isMenuOpen: false,
      textFilter: '',
    }
  },
  computed: {
    options() {
      return uniqBy(this.records, this.field).filter(
        (record) => get(record, this.field, '') !== ''
      )
    },
    shownOptions() {
      if (this.textFilter) {
        return this.options.filter((option) => {
          return get(option, this.field, '')
            .toString()
            .toLowerCase()
            .includes(this.textFilter.toLowerCase())
        })
      } else {
        return this.options
      }
    },
    someSelected() {
      return !this.allSelected && this.selected.length > 0
    },
    allSelected() {
      return this.selected.length === this.options.length
    },
    isFilterApplied() {
      return !this.allSelected
    },
  },
  watch: {
    options() {
      this.selected = this.options.map((option) => get(option, this.field, ''))
    },
    selected(val) {
      if (this.isFilterApplied) {
        this.$emit('change', { field: this.field, value: val, type: 'text' })
      } else {
        this.$emit('change', { field: this.field, value: null, type: 'text' })
      }
    },
  },
  mounted() {
    this.selected = this.options.map((option) => get(option, this.field, ''))
  },
  methods: {
    onSelectAllToggle() {
      if (this.allSelected || this.someSelected) {
        this.selected = []
      } else {
        this.selected = this.options.map((option) =>
          get(option, this.field, '')
        )
      }
    },
  },
}
</script>

<style scoped>
.v-simple-checkbox .v-icon {
  color: var(--v-primary-base) !important;
}
</style>
