<template>
  <v-card class="pa-4">
    <v-row no-gutters class="mb-4" align="center" justify-md="space-between">
      <v-col
        class="text-subtitle-1 font-weight-bold text-sm-h6 text-h5 text-no-wrap mt-1"
        cols="12"
        md="4"
        lg="3"
      >
        {{ title }}
        <v-tooltip v-if="tooltip" top content-class="tooltip elevation-4">
          <template #activator="{ on, attrs }">
            <v-icon
              right
              small
              color="ap-gray"
              class="mb-1"
              v-bind="attrs"
              v-on="on"
            >
              $mdi-information-outline
            </v-icon>
          </template>
          <span>
            {{ tooltip }}
          </span>
        </v-tooltip>
      </v-col>

      <v-col cols="12" lg="5" md="8">
        <slot
          name="search"
          :search="search"
          :updateSearch="onUpdateSearch"
          class="mt-1"
        >
          <v-text-field
            v-model="search"
            placeholder="Search"
            class="base-search-field"
            single-line
            outlined
            clearable
            dense
            append-icon="$mdi-magnify"
            hide-details
          />
        </slot>
      </v-col>

      <v-col
        cols="12"
        md="12"
        lg="4"
        class="d-flex justify-sm-start justify-lg-end mt-1 flex-wrap"
      >
        <slot name="action" />
      </v-col>
    </v-row>

    <!-- base table -->
    <v-data-table
      v-model="selectedRecords"
      class="ap-base-table customScrollbar"
      :class="{ selectable: showSelect }"
      :headers="computedHeaders"
      :items="filteredRecords"
      :items-per-page="perPage"
      :loading="isLoading"
      :page.sync="page"
      :search="search"
      :show-select="showSelect"
      :server-items-length="totalItems"
      sort-by="number"
      must-sort
      fixed-header
      mobile-breakpoint="0"
      hide-default-header
      hide-default-footer
      @current-items="onItemsUpdate"
      @update:options="onOptionsUpdate"
      @click:row="(item) => $emit('open', item)"
    >
      <template #header="{ props, on }">
        <thead class="v-data-table-header">
          <vue-draggable
            v-model="computedHeaders"
            tag="tr"
            draggable=".draggable"
            :disabled="disableDragging"
          >
            <th
              v-for="header in computedHeaders"
              :key="header.value"
              :class="[
                {
                  draggable: isDraggable(header),
                  'cursor-pointer': isSortable(header),
                  sticky: header.sticky,
                },
                getSortingClass(header, props.options),
              ]"
              :style="{
                width: header.width ? `${header.width}px` : undefined,
              }"
              @click="isSortable(header) && on.sort(header.value)"
            >
              <!-- select all checkbox -->
              <div
                v-if="header.value === 'data-table-select'"
                style="width: 1px"
              >
                <v-simple-checkbox
                  v-ripple
                  :value="props.everyItem"
                  :indeterminate="!props.everyItem && props.someItems"
                  @input="(v) => on['toggle-select-all'](!props.someItems)"
                />
              </div>

              <!-- normal header -->
              <div v-else class="d-flex align-center">
                <div class="d-flex align-center">
                  <div>{{ header.text }}</div>
                  <component
                    :is="getFilterComponent(header.filterType)"
                    v-if="
                      isFilterVisible && getFilterComponent(header.filterType)
                    "
                    :field="header.value"
                    :records="records"
                    :disabled="isLoading"
                    @change="onFilterChange"
                  />
                </div>
                <v-icon
                  v-if="isSortable(header)"
                  size="18"
                  class="v-data-table-header__icon"
                >
                  $sort
                </v-icon>

                <!-- resizable grip -->
                <div
                  v-if="isResizable(header)"
                  class="resize-grip"
                  @mousedown="onResizeGripStart"
                />
              </div>
            </th>
          </vue-draggable>
        </thead>
      </template>

      <template #item.estimatedSecondsToMerge="{ item }">
        <div class="d-flex align-center ap-red--text">
          <v-icon small color="error" class="mr-1"> $mdi-timer-outline </v-icon>
          <div style="margin-top: 2px">
            {{ item.estimatedSecondsToMerge }} sec
          </div>
        </div>
      </template>

      <template #item.matchConfidenceScore="{ item }">
        <div
          class="mcs-circle text-capitalize"
          :class="item.matchConfidenceScore"
        >
          <span class="symbol ap-white--text text-capitalize">{{
            item.matchConfidenceScoreSymbol
          }}</span>
        </div>
      </template>

      <template #item.billingStreet="{ item }">
        <span class="text-truncate">{{ item.billingStreet }}</span>
      </template>

      <template #item.ownerName="{ item }">
        <span class="primary--text text-truncate">{{ item.ownerName }}</span>
      </template>

      <template #item.name="{ item }">
        <span class="primary--text text-truncate">{{ item.name }}</span>
      </template>

      <template #footer="{ props }">
        <ApPagination
          v-if="props.pagination.pageCount > 0"
          v-model="page"
          :total-pages="props.pagination.pageCount"
          :total-items="props.pagination.itemsLength"
          :items-per-page="props.pagination.itemsPerPage"
          items-designation="duplicate sets"
          is-range-pager-visible
          class="mt-3 justify-center justify-sm-space-between"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { defaultTo } from 'lodash-es'
import { add, isWithinInterval, parseISO } from 'date-fns'
import VueDraggable from 'vuedraggable'
import ApPagination from '../common/ApPagination'
import BaseTableHeaderDateFilter from './BaseTableHeaderDateFilter'
import BaseTableHeaderTextFilter from './BaseTableHeaderTextFilter'
import { formatDate } from '@/utils/dates'
import { get } from 'lodash-es'

export default {
  components: {
    VueDraggable,
    ApPagination,
    BaseTableHeaderDateFilter,
    BaseTableHeaderTextFilter,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    headers: {
      type: Array,
      default: () => [],
    },
    records: {
      type: Array,
      default: () => [],
    },
    perPage: {
      type: Number,
      default: 20,
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    disableSorting: {
      type: Boolean,
      default: false,
    },
    disableDragging: {
      type: Boolean,
      default: false,
    },
    disableResizing: {
      type: Boolean,
      default: false,
    },
    dateTimeFormat: {
      type: String,
      default: 'MMMM dd, yyyy K:mm aaa',
    },
  },
  data() {
    return {
      formatDate,
      page: 1,
      params: {},
      search: '',
      searchTimeout: undefined,
      selectedRecords: [],
      computedHeaders: [],
      filters: {},
      resizingHeaderEl: null,
      resizingStartOffset: 0,
      showFilters: false,
    }
  },
  computed: {
    filteredRecords() {
      const records = []

      for (let i = 0, len = this.records.length; i < len; i++) {
        const record = this.records[i]
        let addRecord = true

        for (const field in this.filters) {
          const filter = this.filters[field]
          const recordField = get(record, field, '')

          if (filter.value) {
            if (filter.type === 'text') {
              if (!filter.value.includes(recordField)) {
                addRecord = false
                break
              }
            } else if (filter.type === 'date') {
              const { start, end } = filter.value

              if (
                !isWithinInterval(parseISO(recordField), {
                  start: parseISO(start),
                  end: add(parseISO(end), { days: 1 }),
                })
              ) {
                addRecord = false
              }
            }
          }
        }

        if (addRecord) records.push(record)
      }

      return records
    },
    isFilterVisible() {
      return this.records.length > 0 && this.showFilters
    },
  },
  watch: {
    selectedRecords(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.selectedRecords = val
    },
    headers() {
      this.buildTableHeaders()
    },
    search(val) {
      clearTimeout(this.searchTimeout)
      this.params.search = val

      this.searchTimeout = setTimeout(() => {
        this.page = 1
        this.params.page = 1
        this.$emit('fetchData', this.params)
      }, 1200)
    },
  },
  mounted() {
    this.buildTableHeaders()
    window.addEventListener('resize', this.setPositions)

    if (!this.disableResizing) {
      document.addEventListener('mouseup', this.onResizeGripEnd)
      document.addEventListener('mousemove', this.onResizeGripMove)
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setPositions)

    if (!this.disableResizing) {
      document.removeEventListener('mouseup', this.onResizeGripEnd)
      document.removeEventListener('mousemove', this.onResizeGripMove)
    }
  },
  methods: {
    onResizeGripStart(event) {
      const th = get(event, 'target.parentElement.parentElement', null)

      if (th) {
        event.preventDefault()
        event.stopPropagation()
        this.resizingStartOffset = th.offsetWidth - event.pageX
        this.resizingHeaderEl = th
      }
    },
    onResizeGripEnd(event) {
      if (this.resizingHeaderEl) {
        event.preventDefault()
        event.stopPropagation()
        this.resizingHeaderEl = null
      }
    },
    onResizeGripMove(event) {
      if (this.resizingHeaderEl) {
        const width = this.resizingStartOffset + event.pageX

        this.resizingHeaderEl.style.minWidth = `${width}px`
        this.resizingHeaderEl.style.maxWidth = `${width}px`
      }
    },
    setPositions() {
      this.$nextTick(() => {
        const headers = this.$el.querySelectorAll('.v-data-table-header th')
        const rows = this.$el.querySelectorAll('tbody tr')

        if (headers.length === 0 || rows.length === 0) return

        const headersWidths = Array.from(headers).map((e) => e.clientWidth)

        // left on stickies
        let stickyLeft = 0

        this.computedHeaders.forEach((h, index) => {
          if (h.sticky) {
            const left = stickyLeft + 'px'

            stickyLeft += headersWidths[index] + 1

            if (headers[index]) headers[index].style.left = left
            rows.forEach((r) => {
              if (r.children[index]) r.children[index].style.left = left
            })
          }
        })
      })
    },
    onItemsUpdate() {
      this.selectedRecords = []
      this.setPositions()
    },
    buildTableHeaders() {
      const prependHeaders = []
      const appendHeaders = []

      if (this.showSelect) {
        prependHeaders.push({
          text: '',
          width: 58,
          sticky: true,
          freeze: true,
          cellClass: 'sticky',
          value: 'data-table-select',
        })
      }

      this.computedHeaders = [
        ...prependHeaders,
        ...this.headers.map((h) => {
          h.sortable = typeof h.sortable !== 'undefined' ? h.sortable : true
          h.cellClass = h.sticky ? 'sticky' : ''

          return h
        }),
        ...appendHeaders,
      ]
    },
    onFilterChange({ field, value, type }) {
      // if it's null and the filter is already empty don't trigger updates
      if (!value && !this.filters[field]) return

      this.$set(this.filters, field, { value, type })
    },
    onOptionsUpdate(options) {
      this.params.page = defaultTo(options.page, 1)

      if (options.sortBy && options.sortBy[0]) {
        this.params.orderByField = this.mapSortColumn(options.sortBy[0])
        this.params.orderBy = options.sortDesc[0] ? 'desc' : 'asc'
        this.params.search = this.search
        if (!this.params.searchField) {
          this.params.searchField = this.params.orderByField
        }
      }

      this.$emit('fetchData', this.params)
    },
    mapSortColumn(column) {
      let target

      switch (column) {
        case 'final_record.source':
          target = 'query_entity_name'
          break
        default:
          target = column
      }

      return target
    },
    isResizable(header) {
      return !this.disableResizing && !header.freeze
    },
    isDraggable(header) {
      return header.value !== 'data-table-select'
    },
    isSortable(header) {
      return !this.disableSorting && header.sortable
    },
    getSortingClass(header, options) {
      if (!this.isSortable(header)) return ''

      const index = options.sortBy.findIndex((v) => v === header.value)

      if (index === -1) return ''

      return `active ${options.sortDesc[index] ? 'desc' : 'asc'}`
    },
    getFilterComponent(filterType) {
      const filters = {
        text: 'BaseTableHeaderTextFilter',
        date: 'BaseTableHeaderDateFilter',
      }

      return filters[filterType]
    },
    onUpdateSearch(val) {
      if (typeof val === 'object' && val !== null) {
        if (val.filters.length === 0) {
          this.search = null

          return
        }

        // For now only one search param is supported
        this.params.searchField = val.filters[0].value
        this.search = val.filters[0].searchValue
      } else {
        this.search = val
      }
    },
  },
}
</script>

<style lang="scss">
.ap-base-table {
  position: relative;

  .v-progress-linear--absolute,
  .v-data-table__progress th {
    z-index: 5 !important;
  }

  thead {
    th {
      position: relative;
      padding-right: 28px !important;
      background-color: var(--v-ap-light-grey-base) !important;

      .v-data-table-header__icon {
        position: absolute;
        right: 8px;
        top: 8px;
      }
    }
  }

  tbody {
    .text-truncate {
      max-width: 24em;
      display: inline-block;
    }

    & > tr:not(.v-data-table__empty-wrapper) {
      position: relative;
      cursor: pointer;

      &:hover {
        &:after {
          background-color: rgba(34, 73, 121, 0.8);
          position: absolute;
          display: block;
          cursor: pointer;
          left: 0;
          height: 48px;
          width: 100%;
          font-weight: 900;
          font-size: 13px;
          line-height: 48px;
          z-index: 8;
          text-align: center;
          color: white;
          content: 'REVIEW RECORD';
          pointer-events: none;
          user-select: none;
        }
      }
    }
  }

  thead tr:not(.v-data-table__progress) th.sticky {
    left: 0;
    position: sticky !important;
    top: auto;
    background-color: var(--v-ap-light-grey-base) !important;
    z-index: 3 !important;
  }

  tbody {
    tr td.sticky {
      left: 0;
      position: sticky !important;
      top: auto;
      background-color: #fff;
      z-index: 3 !important;
    }
  }

  &.selectable {
    tbody > tr:not(.v-data-table__empty-wrapper) {
      &:hover {
        &:after {
          left: 58px;
          width: calc(100% - 58px);
        }
      }
    }
  }

  .resize-grip {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 5px;
    cursor: col-resize;
  }

  .mcs-circle {
    width: 17px;
    height: 17px;
    position: relative;
    border-radius: 50%;
    text-align: center;
    line-height: 17px;

    .symbol {
      font-weight: 900;
      font-size: 12px;
    }

    &.low {
      background-color: var(--v-ap-orange-base);
    }
    &.medium {
      background-color: var(--v-ap-yellow-base);
    }
    &.high {
      background-color: var(--v-ap-green-base);
    }
  }
}

.base-search-field {
  max-width: 360px;
}

.customScrollbar {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #b9b9b9;
    box-shadow: none;
  }

  ::-webkit-scrollbar-track {
    background-color: var(--v-ap-light-grey-base);
  }

  ::-webkit-scrollbar-track:vertical {
    border-left: 1px solid lightgrey;
    border-top: 1px solid lightgrey;
  }

  ::-webkit-scrollbar-track:horizontal {
    border-bottom: 1px solid lightgrey;
  }

  ::-webkit-scrollbar-corner {
    background: #f0f0f0;
    border-bottom: 1px solid lightgrey;
  }
}

.v-simple-checkbox .v-icon {
  color: var(--v-primary-base) !important;
}
</style>
