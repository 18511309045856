<template>
  <div
    ref="filterContainer"
    class="advanced-filter mt-1"
    :style="{ maxWidth: getMaxWidth }"
  >
    <v-menu
      v-model="menu"
      offset-y
      bottom
      :max-width="filterMenuWidth"
      :close-on-content-click="false"
      content-class="filter-menu"
    >
      <template #activator="{ on }">
        <div class="d-flex">
          <div
            class="filter-btn d-flex justify-center align-center rounded-tl rounded-bl justify-space-between px-3"
            v-on="on"
          >
            <v-icon size="18"> $mdi-filter-outline </v-icon>
            <span class="ap-dark-gray--text noselect"> Filters </span>
            <v-icon
              size="18"
              class="toggle-menu-icon"
              :class="{ active: menu }"
            >
              $mdi-chevron-down
            </v-icon>
          </div>
          <v-text-field
            v-if="activeFilters.length === 0"
            placeholder="Search"
            color="ap-dark-gray"
            single-line
            outlined
            clearable
            dense
            append-icon="$mdi-magnify"
            hide-details
            :readonly="menu"
            :value="search"
            @input="updateSearch($event)"
          />
          <v-select
            v-else
            v-model="activeFilters"
            :items="activeFilters"
            append-icon=""
            outlined
            hide-details
            single-line
            dense
            multiple
            readonly
          >
            <template #selection="{ item, index }">
              <v-chip
                v-if="index === 0"
                text-color="ap-dark-gray"
                close
                close-icon="$mdi-close"
                @click:close="
                  removeFilter(index)
                  applyFilters()
                "
              >
                <span>{{ item.searchValue }}</span>
              </v-chip>
              <span v-if="index === 1" class="ap-dark-gray--text text-caption">
                (+{{ value.length - 1 }} others)
              </span>
            </template>
          </v-select>
        </div>
      </template>
      <div class="d-flex">
        <div class="filter-list px-4 py-2">
          <v-list color="ap-light-grey" flat dense>
            <v-list-item
              v-for="filter in filterFields"
              :key="filter.id"
              class="filter-item"
              :ripple="false"
              @click="setFilter(filter)"
            >
              {{ filter.label }}
            </v-list-item>
          </v-list>
        </div>
        <div class="content px-4 pt-4 d-flex flex-column flex-grow-1 white">
          <div
            v-for="(filter, idx) in filtersDraft"
            :key="filter.id"
            class="details d-flex align-center mb-3 justify-space-between"
          >
            <v-col cols="2" class="px-0">
              <span class="label">
                {{ filter.label }}
              </span>
            </v-col>
            <v-col cols="4" class="pl-0 filter-operator">
              <v-select
                v-model="filter.operator"
                :items="getOperators(filter.type)"
                return-object
                solo
                hide-details
                dense
                item-text="label"
                outlined
                full-width
                color="ap-dark-gray"
                class="mx-2 font-weight-bold"
                append-icon="$mdi-chevron-down"
              />
            </v-col>
            <v-col cols="5" class="px-0 filter-value">
              <v-text-field
                v-if="filter.type === 'text' && !filterOptions[filter.value]"
                v-model="filter.searchValue"
                solo
                flat
                hide-details
                dense
                height="40"
                outlined
                color="ap-dark-gray"
                class="font-weight-bold"
              />
              <DatePicker
                v-if="filter.type === 'date'"
                v-model="filter.searchValue"
                placeholder="Select date"
                :textfield="true"
                :max="today"
              />
              <SelectDrop
                v-if="filter.type === 'text' && filterOptions[filter.value]"
                v-model="filter.searchValue"
                :items="filterOptions[filter.value]"
                item-text="name"
                width="100%"
                :return-object="true"
                color="ap-dark-gray"
                scale-menu-to-fit
              />
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn icon small>
                <v-icon size="22" @click="removeFilter(idx)">
                  $mdi-delete-outline
                </v-icon>
              </v-btn>
            </v-col>
          </div>
          <div class="actions d-flex align-center py-2 px-4">
            <v-btn
              text
              small
              class="ap-blue--text text-none font-weight-regular"
              @click="
                clearFilters()
                applyFilters()
              "
            >
              Clear all filters
            </v-btn>
            <v-spacer />
            <v-btn
              color="primary"
              outlined
              height="40"
              class="mr-2"
              @click="closeMenu()"
            >
              Cancel
            </v-btn>
            <v-btn height="40" color="primary" @click="applyFilters()">
              Apply Filters
            </v-btn>
          </div>
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DatePicker from './DatePicker'
import SelectDrop from './SelectDrop'

export default {
  components: {
    DatePicker,
    SelectDrop,
  },
  props: {
    search: {
      type: String,
      default: '',
    },
    updateSearch: {
      type: Function,
      required: true,
    },
    maxWidth: {
      type: String,
      default: '600px',
      required: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
      filtersDraft: [],
      activeFilters: [],
      today: new Date().toISOString(),
      filterMenuWidth: 0,
    }
  },
  computed: {
    ...mapState('dashboard', [
      'filterFields',
      'filterTextOperators',
      'filterDateOperators',
      'filterNumOperators',
      'filterOptions',
    ]),
    getMaxWidth() {
      return this.fullWidth === true ? '100%' : this.maxWidth
    },
  },
  created() {
    this.getFilterFields()
    this.getFilterOperators()
    this.getFilterOptions()
  },
  mounted() {
    window.addEventListener('resize', this.calculateFilterContainerWidth)
    this.calculateFilterContainerWidth()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calculateFilterContainerWidth)
  },
  methods: {
    ...mapActions('dashboard', [
      'getFilterFields',
      'getFilterOperators',
      'getFilterOptions',
    ]),
    getOperators(type) {
      if (type === 'text') return this.filterTextOperators
      if (type === 'number') return this.filterNumOperators
      if (type === 'date') return this.filterDateOperators

      return false
    },
    // Not used ATM because API supports only 1 filter
    appendFilter(filter) {
      const matchingFilter = this.filtersDraft.filter((el) => {
        return el.id === filter.id
      })

      if (matchingFilter.length === 0) {
        const filterValue = {
          ...filter,
          operator: null,
          searchValue: null,
        }

        this.filtersDraft.push(filterValue)
      }
    },
    setFilter(filter) {
      const filterValue = {
        ...filter,
        operator: this.getOperators(filter.type)[0], // Set first as selected until API supports different operators,
        searchValue: null,
      }

      this.filtersDraft = [filterValue]
    },
    removeFilter(idx) {
      this.filtersDraft.splice(idx, 1)
    },
    closeMenu() {
      this.menu = false
    },
    calculateFilterContainerWidth() {
      this.filterMenuWidth = this.$refs.filterContainer.clientWidth
    },
    clearFilters() {
      this.filtersDraft.splice(0)
    },
    applyFilters() {
      this.activeFilters = [...this.filtersDraft]
      this.updateSearch({
        filters: this.activeFilters,
      })
      this.closeMenu()
    },
  },
}
</script>

<style lang="scss" scoped>
.advanced-filter {
  .filter-btn {
    cursor: pointer;
    min-width: 140px;
    border: solid 1px var(--v-ap-dark-gray-base);
    border-right: none;
    background: var(--v-ap-light-grey-base);
  }

  .toggle-menu-icon {
    &.active {
      transform: rotate(180deg);
    }
  }

  ::v-deep .v-input {
    .v-input__slot {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  ::v-deep .v-select .v-chip {
    border-radius: 5px !important;
  }
}

.filter-menu {
  border: solid 1px var(--v-ap-dark-gray-base);
  border-top: none;

  .filter-list {
    min-width: 140px;
    background: var(--v-ap-light-grey-base);
    border-right: solid 1px var(--v-ap-dark-gray-base);

    .filter-item {
      font-size: 13px;
      cursor: pointer;
    }
  }

  .content {
    position: relative;
    padding-bottom: 72px;
    overflow-x: auto;

    .details {
      height: 40px;
    }

    .label {
      font-size: 13px;
    }

    .filter-operator {
      min-width: 105px;
    }

    .filter-value {
      min-width: 150px;
    }

    .actions {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 72px;
      border-top: solid 1px var(--v-ap-gray-base);
    }

    ::v-deep .v-select {
      .v-select__selection {
        font-size: 13px;
        color: var(--v-ap-dark-gray-base);
      }
    }

    ::v-deep .v-text-field {
      input {
        font-size: 13px;
        color: var(--v-ap-dark-gray-base) !important;
      }
    }

    ::v-deep .select-drop {
      input {
        font-size: 13px;
        color: var(--v-ap-dark-gray-base) !important;
        font-weight: 900;
      }
    }
  }
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
