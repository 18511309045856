<template>
  <v-menu
    v-model="showMenu"
    :close-on-content-click="false"
    :disabled="disabled"
    offset-y
    bottom
    left
  >
    <template #activator="{ on, attrs }">
      <v-badge
        :value="isFilterApplied"
        dot
        top
        color="ap-orange"
        offset-y="10"
        offset-x="7"
      >
        <v-btn
          icon
          small
          v-bind="attrs"
          v-on="on"
          @click.stop.prevent="() => {}"
        >
          <v-icon small> $mdi-filter-variant </v-icon>
        </v-btn>
      </v-badge>
    </template>

    <slot />
  </v-menu>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    isFilterApplied: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMenu: false,
    }
  },
  watch: {
    value(val) {
      this.showMenu = val
    },
    showMenu(val) {
      this.$emit('input', val)
    },
  },
}
</script>
