var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-4"},[_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":"","align":"center","justify-md":"space-between"}},[_c('v-col',{staticClass:"text-subtitle-1 font-weight-bold text-sm-h6 text-h5 text-no-wrap mt-1",attrs:{"cols":"12","md":"4","lg":"3"}},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.tooltip)?_c('v-tooltip',{attrs:{"top":"","content-class":"tooltip elevation-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-1",attrs:{"right":"","small":"","color":"ap-gray"}},'v-icon',attrs,false),on),[_vm._v(" $mdi-information-outline ")])]}}],null,false,1338526993)},[_c('span',[_vm._v(" "+_vm._s(_vm.tooltip)+" ")])]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","lg":"5","md":"8"}},[_vm._t("search",function(){return [_c('v-text-field',{staticClass:"base-search-field",attrs:{"placeholder":"Search","single-line":"","outlined":"","clearable":"","dense":"","append-icon":"$mdi-magnify","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},{"search":_vm.search,"updateSearch":_vm.onUpdateSearch})],2),_c('v-col',{staticClass:"d-flex justify-sm-start justify-lg-end mt-1 flex-wrap",attrs:{"cols":"12","md":"12","lg":"4"}},[_vm._t("action")],2)],1),_c('v-data-table',{staticClass:"ap-base-table customScrollbar",class:{ selectable: _vm.showSelect },attrs:{"headers":_vm.computedHeaders,"items":_vm.filteredRecords,"items-per-page":_vm.perPage,"loading":_vm.isLoading,"page":_vm.page,"search":_vm.search,"show-select":_vm.showSelect,"server-items-length":_vm.totalItems,"sort-by":"number","must-sort":"","fixed-header":"","mobile-breakpoint":"0","hide-default-header":"","hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"current-items":_vm.onItemsUpdate,"update:options":_vm.onOptionsUpdate,"click:row":function (item) { return _vm.$emit('open', item); }},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('vue-draggable',{attrs:{"tag":"tr","draggable":".draggable","disabled":_vm.disableDragging},model:{value:(_vm.computedHeaders),callback:function ($$v) {_vm.computedHeaders=$$v},expression:"computedHeaders"}},_vm._l((_vm.computedHeaders),function(header){return _c('th',{key:header.value,class:[
              {
                draggable: _vm.isDraggable(header),
                'cursor-pointer': _vm.isSortable(header),
                sticky: header.sticky,
              },
              _vm.getSortingClass(header, props.options) ],style:({
              width: header.width ? ((header.width) + "px") : undefined,
            }),on:{"click":function($event){_vm.isSortable(header) && on.sort(header.value)}}},[(header.value === 'data-table-select')?_c('div',{staticStyle:{"width":"1px"}},[_c('v-simple-checkbox',{directives:[{name:"ripple",rawName:"v-ripple"}],attrs:{"value":props.everyItem,"indeterminate":!props.everyItem && props.someItems},on:{"input":function (v) { return on['toggle-select-all'](!props.someItems); }}})],1):_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',[_vm._v(_vm._s(header.text))]),(
                    _vm.isFilterVisible && _vm.getFilterComponent(header.filterType)
                  )?_c(_vm.getFilterComponent(header.filterType),{tag:"component",attrs:{"field":header.value,"records":_vm.records,"disabled":_vm.isLoading},on:{"change":_vm.onFilterChange}}):_vm._e()],1),(_vm.isSortable(header))?_c('v-icon',{staticClass:"v-data-table-header__icon",attrs:{"size":"18"}},[_vm._v(" $sort ")]):_vm._e(),(_vm.isResizable(header))?_c('div',{staticClass:"resize-grip",on:{"mousedown":_vm.onResizeGripStart}}):_vm._e()],1)])}),0)],1)]}},{key:"item.estimatedSecondsToMerge",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center ap-red--text"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"error"}},[_vm._v(" $mdi-timer-outline ")]),_c('div',{staticStyle:{"margin-top":"2px"}},[_vm._v(" "+_vm._s(item.estimatedSecondsToMerge)+" sec ")])],1)]}},{key:"item.matchConfidenceScore",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"mcs-circle text-capitalize",class:item.matchConfidenceScore},[_c('span',{staticClass:"symbol ap-white--text text-capitalize"},[_vm._v(_vm._s(item.matchConfidenceScoreSymbol))])])]}},{key:"item.billingStreet",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.billingStreet))])]}},{key:"item.ownerName",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"primary--text text-truncate"},[_vm._v(_vm._s(item.ownerName))])]}},{key:"item.name",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"primary--text text-truncate"},[_vm._v(_vm._s(item.name))])]}},{key:"footer",fn:function(ref){
                  var props = ref.props;
return [(props.pagination.pageCount > 0)?_c('ApPagination',{staticClass:"mt-3 justify-center justify-sm-space-between",attrs:{"total-pages":props.pagination.pageCount,"total-items":props.pagination.itemsLength,"items-per-page":props.pagination.itemsPerPage,"items-designation":"duplicate sets","is-range-pager-visible":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()]}}]),model:{value:(_vm.selectedRecords),callback:function ($$v) {_vm.selectedRecords=$$v},expression:"selectedRecords"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }