<template>
  <BaseTableHeaderMenuFilter
    v-model="isMenuOpen"
    :is-filter-applied="selected !== 1"
  >
    <v-sheet class="px-4">
      <v-radio-group
        v-model="selected"
        dense
        class="font-weight-bold"
        mandatory
      >
        <v-radio
          v-for="(option, index) in options"
          :key="index"
          class="mb-4"
          :label="option.text"
          :value="option.value"
        />
        <div class="d-flex align-center">
          <v-radio class="flex-grow-1" :value="5">
            <template #label>
              <div>{{ customDatesLabel }}</div>
            </template>
          </v-radio>
          <v-menu
            v-model="showCustomDates"
            :close-on-content-click="false"
            offset-y
            bottom
            left
          >
            <template #activator="{ on, attrs }">
              <v-btn icon class="mt-n2" v-bind="attrs" v-on="on">
                <v-icon>$mdi-calendar-month-outline</v-icon>
              </v-btn>
            </template>
            <v-date-picker
              v-model="customDates"
              first-day-of-week="1"
              no-title
              range
            />
          </v-menu>
        </div>
      </v-radio-group>
    </v-sheet>
  </BaseTableHeaderMenuFilter>
</template>

<script>
import { format, formatISO, sub, parseISO } from 'date-fns'
import BaseTableHeaderMenuFilter from './BaseTableHeaderMenuFilter'

export default {
  components: {
    BaseTableHeaderMenuFilter,
  },
  props: {
    field: {
      type: String,
      default: '',
    },
  },
  data() {
    const today = format(new Date(), 'yyyy-MM-dd')
    const prev = format(sub(new Date(), { days: 10 }), 'yyyy-MM-dd')

    return {
      isMenuOpen: false,
      showCustomDates: false,
      customDates: [prev, today],
      finalCustomDates: [prev, today],
      selected: 1,
      options: [
        {
          text: 'All time',
          value: 1,
        },
        {
          text: 'Last 7 days',
          value: 2,
        },
        {
          text: 'Last 30 days',
          value: 3,
        },
        {
          text: 'Last 3 months',
          value: 4,
        },
      ],
    }
  },
  computed: {
    customDatesLabel() {
      if (this.finalCustomDates.length === 2) {
        return `${format(
          parseISO(this.finalCustomDates[0]),
          'MMM dd, yyyy'
        )} - ${format(parseISO(this.finalCustomDates[1]), 'MMM dd, yyyy')}`
      }

      return ''
    },
  },
  watch: {
    selected(val) {
      const today = new Date()
      let start = undefined
      let end = undefined

      switch (val) {
        case 1:
          this.$emit('change', { field: this.field, value: null })
          this.isMenuOpen = false

          return
        case 2:
          start = formatISO(sub(today, { days: 7 }))
          end = formatISO(today)
          break
        case 3:
          start = formatISO(sub(today, { days: 30 }))
          end = formatISO(today)
          break
        case 4:
          start = formatISO(sub(today, { months: 3 }))
          end = formatISO(today)
          break
        case 5:
          start = formatISO(parseISO(this.finalCustomDates[0]))
          end = formatISO(parseISO(this.finalCustomDates[1]))
          break
      }

      this.$emit('change', {
        field: this.field,
        value: { start, end },
        type: 'date',
      })
      this.isMenuOpen = false
    },
    customDates(val) {
      if (val && val.length > 1) {
        const prevSelected = this.selected

        this.finalCustomDates = val
        this.selected = 5

        if (this.selected === prevSelected) {
          const start = formatISO(parseISO(this.finalCustomDates[0]))
          const end = formatISO(parseISO(this.finalCustomDates[1]))

          this.$emit('change', {
            field: this.field,
            value: { start, end },
            type: 'date',
          })
          this.isMenuOpen = false
        }
      }
    },
  },
}
</script>
